import { Image } from "@tiptap/extension-image"

const ImageResize = Image.extend({
    addNodeView() {
        return ({ node, editor, getPos }) => {
            const { view, options } = editor
            const editable = options.editable
            const style = node.attrs.style || ""
            const wrapper = document.createElement("div")
            const container = document.createElement("div")
            const img = document.createElement("img")

            const updateNodeAttributes = () => {
                if (typeof getPos === "function") {
                    const newAttrs = { ...node.attrs, style: img.style.cssText }
                    view.dispatch(view.state.tr.setNodeMarkup(getPos(), null, newAttrs))
                }
            }

            const applyResizeHandles = () => {
                const handleSize = 8
                const positions = [
                    { top: "-4px", left: "-4px", cursor: "nwse-resize" },
                    { top: "-4px", right: "-4px", cursor: "nesw-resize" },
                    { bottom: "-4px", left: "-4px", cursor: "nesw-resize" },
                    { bottom: "-4px", right: "-4px", cursor: "nwse-resize" },
                ]

                positions.forEach(({ top, left, right, bottom, cursor }) => {
                    const handle = document.createElement("div")
                    handle.style.position = "absolute"
                    handle.style.width = `${handleSize}px`
                    handle.style.height = `${handleSize}px`
                    handle.style.background = "#fff"
                    handle.style.border = "1px solid #000"
                    handle.style.zIndex = "10"
                    handle.style.cursor = cursor
                    handle.classList.add("resize-handle")

                    if (top) handle.style.top = top
                    if (left) handle.style.left = left
                    if (right) handle.style.right = right
                    if (bottom) handle.style.bottom = bottom

                    handle.addEventListener("mousedown", (event) => {
                        event.preventDefault()

                        const startX = event.clientX
                        const startWidth = img.offsetWidth

                        const onMouseMove = (moveEvent: MouseEvent) => {
                            const deltaX = moveEvent.clientX - startX
                            const newWidth = startWidth + deltaX
                            img.style.width = `${newWidth}px`
                            updateNodeAttributes()
                        }

                        const onMouseUp = async () => {
                            document.removeEventListener("mousemove", onMouseMove)
                            document.removeEventListener("mouseup", onMouseUp)

                            // Update base64 data after resizing
                            const canvas = document.createElement("canvas")
                            const context = canvas.getContext("2d")
                            canvas.width = img.width
                            canvas.height = img.height
                            context?.drawImage(img, 0, 0, canvas.width, canvas.height)
                            const newBase64 = canvas.toDataURL("image/jpeg", 0.7)
                            const newAttrs = {
                                ...node.attrs,
                                src: newBase64,
                                width: `${img.width}px`,
                                height: `${img.height}px`,
                            }
                            view.dispatch(view.state.tr.setNodeMarkup(getPos(), null, newAttrs))
                        }

                        document.addEventListener("mousemove", onMouseMove)
                        document.addEventListener("mouseup", onMouseUp)
                    })

                    container.appendChild(handle)
                })
            }

            Object.entries(node.attrs).forEach(([key, value]) => {
                if (value !== null && value !== undefined) {
                    img.setAttribute(key, value.toString())
                }
            })

            wrapper.style.display = "inline-block"
            wrapper.style.position = "relative"

            container.style.position = "relative"
            container.style.display = "inline-block"
            container.style.border = editable ? "1px dashed #ccc" : ""
            container.style.cssText += style

            img.style.display = "block"
            img.style.width = node.attrs.width || "100%"
            img.style.height = node.attrs.height || "auto"
            img.draggable = true

            img.addEventListener("click", () => {
                if (editable) {
                    if (container.querySelector(".resize-handle")) {
                        container.querySelectorAll(".resize-handle").forEach((el) => el.remove())
                    } else {
                        applyResizeHandles()
                    }
                }
            })

            wrapper.appendChild(container)
            container.appendChild(img)

            // Ensure clicks outside remove handles
            document.addEventListener("click", (event: Event) => {
                const target = event.target as HTMLElement
                const isClickInside = container.contains(target)
                if (!isClickInside) {
                    container.querySelectorAll(".resize-handle").forEach((el: Element) => el.remove())
                }
            })

            return {
                dom: wrapper,
                update: (updatedNode) => {
                    if (updatedNode.type.name !== "image") {
                        return false
                    }

                    Object.entries(updatedNode.attrs).forEach(([key, value]) => {
                        if (value !== img.getAttribute(key)) {
                            img.setAttribute(key, value?.toString() || "")
                        }
                    })

                    return true
                },
            }
        }
    },
})

export default ImageResize
