import { BaseModel } from "@/shared/models/base-model"
import { FiltersGroup } from "./filter"
import { Grid } from "./grid"

export interface Collection extends BaseModel {
    displayName: string
    description?: string
    typeName: string
    filtersGroups: FiltersGroup[]
    total?: number
    order: number
    isPinned?: boolean
    isFavourites?: boolean
    isHidden?: boolean
    isViewed?: boolean
    isShowingRequests?: boolean
    isRequestForInformation?: boolean
    isSystemDefault?: boolean
    contactId?: string
    alertConfigs?: AlertConfig[]
}

export interface CollectionResponse {
    data: Collection[]
    total: number
}

export interface Tab {
    grid?: Grid
    collection?: Collection
    total: number
    ids: string[]
    type: "grid" | "filterList"
}

export enum AlertTrigger {
    newListing = "newListing",
    newTourInsights = "newTourInsights",
    openHouseUpdates = "openHouseUpdates",
    priceChange = "priceChange",
    statusChange = "statusChange",
    soldListing = "soldListing",
}

export interface AlertConfig {
    _id?: string
    name?: string
    frequency?: FrequencyAlert
    channel?: Channel
    timeToSend?: string
    sendTo?: string
    createdAt?: Date
    updatedAt?: Date
    triggers?: AlertTrigger[]
}

export enum FrequencyAlert {
    immediately = "immediately",
    daily = "daily",
    weekly = "weekly",
    monthly = "monthly",
}

export enum Channel {
    email = "email",
    sms = "sms",
}

export interface SavedSearch {
    _id?: string
    collectionId: string
    displayName: string
    filtersGroups: FiltersGroup[]
    total?: number
    name?: string
    frequency?: FrequencyAlert
    channel?: Channel
    timeToSend?: string
    sendTo?: string
    createdAt?: Date
    updatedAt?: Date
    template?: { _id: string; name: string }
    triggers?: AlertTrigger[]
}
