<template>
    <div class="flex flex-col gap-6">
        <div v-if="showHeader" class="flex gap-4">
            <ArrowLeftIcon class="h-6 w-6 text-primary-color-700 cursor-pointer shrink-0" @click.stop="cancelFilter" />
            <span class="text-gray-900 text-lg font-medium capitalize"> {{ filter.displayName }}</span>
        </div>
        <div class="flex flex-col gap-3">
            <!-- Status field -->
            <GgmsCheckBoxList
                v-if="['status', 'invites.status'].includes(localFilter.field)"
                v-model="multipleModelValues"
                :options="
                    collectionType === 'agent'
                        ? agentStatusOptions
                        : collectionType === 'workflow'
                        ? workflowStatusOptions
                        : statusOptions
                "
            />
            <!-- Invite Status field -->
            <GgmsRadioSelect
                v-else-if="['inviteStatus', 'invites.inviteStatus'].includes(localFilter.field)"
                v-model="localFilter.value"
                :options="inviteStatusOptions"
                @update:model-value="onItemSelect"
            />
            <!-- Should remove if not completed field -->
            <GgmsRadioSelect
                v-else-if="localFilter.field === 'shouldRemoveIfNotCompleted'"
                v-model="localFilter.value"
                :options="shouldRemoveOptions"
                @update:model-value="onItemSelect"
            />
            <!-- Standard Status -->
            <GgmsCheckBoxList
                v-else-if="['standardStatus', 'attributes.standardStatus'].includes(localFilter.field)"
                v-model="multipleModelValues"
                :options="standardStatusOptions"
            />
            <!-- Pool Features -->
            <GgmsCheckBoxList
                v-else-if="['poolFeatures', 'attributes.poolFeatures'].includes(localFilter.field)"
                v-model="multipleModelValues"
                :options="poolFeaturesOptions"
            />
            <!-- Created At -->
            <GgmsRadioSelect
                v-else-if="
                    (localFilter.field === 'createdAt' || localFilter.field === 'attributes.createdAt') &&
                    gridCollectionStore.collectionType === 'listings'
                "
                v-model="localFilter.value"
                :options="createdAtOptions"
                @update:model-value="onItemSelect"
            />
            <!-- City -->
            <GgmsDropdown
                v-else-if="localFilter.field === 'attributes.city'"
                v-model="localFilter.value"
                :options="citiesOptions"
                @update:model-value="onItemSelect"
                option-label="label"
                option-value="value"
                placeholder="Select a City"
                class="w-full"
            />
            <!-- Property Type -->
            <GgmsDropdown
                v-else-if="localFilter.field === 'attributes.propertyType'"
                v-model="localFilter.value"
                :options="propertiesOptions"
                @update:model-value="onItemSelect"
                option-label="label"
                option-value="value"
                placeholder="Select a Property Type"
                class="w-full"
            />
            <!-- Reminder field -->
            <GgmsRadioSelect
                v-else-if="localFilter.field === 'reminder'"
                v-model="localFilter.value"
                :options="reminderOptions"
                @update:model-value="onItemSelect"
            />
            <!-- State field -->
            <GgmsCheckBoxList
                v-else-if="['state', 'isDraft'].includes(localFilter.field)"
                v-model="multipleModelValues"
                :options="stateOptions"
            />
            <!-- Other fields -->
            <GgmsCheckBoxList
                v-else-if="
                    ['role', 'invites?.role.name', 'type', 'templateType', 'typeName', 'isUsed'].includes(
                        localFilter.field
                    )
                "
                v-model="multipleModelValues"
                :options="typeFilterOptions"
            />
            <template v-else-if="filterRules?.length">
                <div v-for="(filterRule, index) in filterRules" :key="index">
                    <!-- Filter Rule -->
                    <div class="flex items-center">
                        <input
                            :id="filterRule"
                            :value="filterRule"
                            v-model="localFilter.rule"
                            name="notification-method"
                            type="radio"
                            class="focus:ring-primary-color-500 h-4 w-4 text-primary-color-600 border-gray-300"
                            @change="changeLocalFilterValueType()"
                        />
                        <label :for="filterRule" class="ml-3 block text-sm font-medium text-gray-700">
                            {{ displayFilterRule(filterRule) }}
                        </label>
                    </div>
                    <div
                        v-if="isBetweenRule && !isNotCompareableAttribute && localFilter.rule === filterRule"
                        class="flex flex-col gap-y-2"
                    >
                        <!-- Double Date and Number fields with between rule -->
                        <div class="flex gap-x-2 w-full mt-3">
                            <template v-if="isDateTypeFilter">
                                <GgmsCalendar
                                    v-model="localFilterStart"
                                    position="below"
                                    :showTime="showTime"
                                    :showDate="true"
                                ></GgmsCalendar>
                                <GgmsCalendar
                                    v-model="localFilterEnd"
                                    position="below"
                                    :showTime="showTime"
                                    :showDate="true"
                                ></GgmsCalendar>
                            </template>
                            <template v-else>
                                <GgmsInput
                                    v-model="localFilterNumberStartValue"
                                    :type="localFilterInputType"
                                    :isAvailableDateFromPast="true"
                                    class="w-full"
                                />
                                <GgmsInput
                                    v-model="localFilterNumberEndValue"
                                    :type="localFilterInputType"
                                    :isAvailableDateFromPast="true"
                                    class="w-full"
                                />
                            </template>
                        </div>
                    </div>
                    <!-- Single fields with any other rules -->
                    <div v-else-if="!isNotCompareableAttribute && localFilter.rule === filterRule" class="mt-4">
                        <GgmsCalendar
                            v-if="isDateTypeFilter"
                            v-model="localFilter.value"
                            position="below"
                            :showTime="showTime"
                            :showDate="true"
                        ></GgmsCalendar>
                        <GgmsInput
                            v-else
                            v-model="localFilter.value"
                            :type="localFilterInputType"
                            :isAvailableDateFromPast="true"
                            class="w-full"
                        />
                    </div>
                </div>
            </template>
            <!-- Lists field -->
            <FilterCollection
                v-else-if="localFilter.field === 'lists._id'"
                :collectionsSelectedState="collectionsSelectedState"
                :hasFilterCollections="hasFilter"
                :typeName="collectionType"
                @onCollectionSelect="onCollectionSelect"
            />
            <!-- Origins field -->
            <FilterOrigin
                v-else-if="['origins', 'origins._id'].includes(localFilter.field)"
                :originsSelectedState="originsSelectedState"
                :hasFilterOrigins="hasFilter"
                :typeName="collectionType"
                @onOriginSelect="onOriginSelect"
            />
            <GgmsRadioSelect
                v-else-if="localFilter.type === 'boolean'"
                v-model="localFilter.value"
                :options="booleanOptions"
                @update:model-value="onItemSelect"
            />

            <!-- Tags field -->
            <FilterTag
                v-else
                :tagsSelectedState="tagsSelectedState"
                :hasFilterTags="hasFilter"
                :typeName="collectionType"
                @onTagSelect="onTagSelect"
            />
            <GgmsButton
                v-if="showButton"
                class="w-fit"
                :disabled="!isFilterValid"
                :small="false"
                @click.stop="applyFilter"
                >{{ filterButtonText }}</GgmsButton
            >
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue"
import GgmsInput from "@/components/GgmsInput.vue"
import GgmsButton from "@/components/GgmsButton.vue"
import FilterTag from "@/components/filters/FilterTag.vue"
import FilterCollection from "@/components/filters/FilterCollection.vue"
import GgmsCheckBoxList from "@/components/GgmsCheckBoxList.vue"
import GgmsRadioSelect from "@/components/GgmsRadioSelect.vue"
import GgmsDropdown from "@/components/GgmsDropdown.vue"
import GgmsCalendar from "@/components/GgmsCalendar.vue"
import FilterOrigin from "@/components/filters/FilterOrigin.vue"
import { ArrowLeftIcon } from "@heroicons/vue/outline"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { useTagStore } from "@/stores/tag"
import { useOriginStore } from "@/stores/origin"
import { Filter } from "@/shared/models/filter"
import { displayFilterRule, getTaskTypes, formatDateToUtc } from "@/shared/utils/helpers"
import statesAndCities from "@/assets/states_and_cities.json"

interface Props {
    filter: Filter
    isUpdated?: boolean
    showHeader?: boolean
    showButton?: boolean
}

const tagStore = useTagStore()
const originStore = useOriginStore()
const gridCollectionStore = useGridCollectionStore()

const props = withDefaults(defineProps<Props>(), { showHeader: true, showButton: true })
const emit = defineEmits(["applyFilter", "cancel"])

const cities = ref<string[]>([])

// Computed property to transform cities into dropdown options
const citiesOptions = computed(() =>
    cities.value.map((city) => ({
        label: city,
        value: city,
    }))
)

// Options for the different property types
const propertiesOptions = [
    { label: "Business Opportunity Property Type", value: "Business Opportunity" },
    { label: "Commercial Lease Property Type", value: "Commercial Lease" },
    { label: "Commercial Sale Property Type", value: "Commercial Sale" },
    { label: "Farm Land Property Type", value: "Farm Land" },
    { label: "Land Property Type", value: "Land" },
    { label: "Mobile Home Property Type", value: "Mobile Home" },
    { label: "Residential Income Property Type", value: "Residential Income" },
    { label: "Residential Lease Property Type", value: "Residential Lease" },
    { label: "Residential Property Type", value: "Residential" },
]

const localFilter = ref<Filter>({
    field: props.filter.field,
    type: props.filter.type,
    rule: props.filter.rule,
    value: props.filter.value,
    displayName: props.filter.displayName,
})
const localFilterTags = ref({
    field: "tags._id",
    type: "string[]",
    rule: "isInIds",
    value: [] as string[],
    displayName: "Tags",
})

const localFilterOrigins = ref({
    field: "origins._id",
    type: "string[]",
    rule: "isInIds",
    value: [] as string[],
    displayName: "Origins",
})

const localFilterCollections = ref({
    field: "lists._id",
    type: "string[]",
    rule: "isInIds",
    value: [] as string[],
    displayName: "Lists",
})

const multipleModelValues = ref<boolean[]>([])
const customFields = ["birthday", "purchaseAnniversary"]

const booleanOptions = [
    { displayName: "Yes", value: true },
    { displayName: "No", value: false },
]

const statusOptions = [
    { displayName: "Pending", value: "pending" },
    { displayName: "Completed", value: "completed" },
]

const standardStatusOptions = [
    { displayName: "Active", value: "Active" },
    { displayName: "Pending", value: "Pending" },
    { displayName: "Active Under Contract", value: "Active Under Contract" },
    { displayName: "Coming Soon", value: "Coming Soon" },
]

const poolFeaturesOptions = [
    { displayName: "Community", value: "Community" },
    { displayName: "Private", value: "Private" },
]

const workflowStatusOptions = [
    { displayName: "On", value: "on" },
    { displayName: "Off", value: "off" },
    { displayName: "Unpublished", value: "unpublished" },
]

const agentStatusOptions = [
    { displayName: "Active", value: "active" },
    { displayName: "Pending", value: "pending" },
    { displayName: "Disabled", value: "disabled" },
]

const inviteStatusOptions = [
    { displayName: "Accepted", value: "accepted" },
    { displayName: "Pending", value: "pending" },
]

const shouldRemoveOptions = [
    { displayName: "Yes", value: "true" },
    { displayName: "No", value: "false" },
]

const reminderOptions = [
    { displayName: "30 Minutes Before", value: "minutes_30" },
    { displayName: "1 Hour Before", value: "hours_1" },
    { displayName: "1 Day Before", value: "days_1" },
    { displayName: "1 Week Before", value: "weeks_1" },
]

const createdAtOptions = [
    { displayName: "Last 24 hours", value: "__last_24_days__" },
    { displayName: "Last 3 days", value: "__last_3_days__" },
    { displayName: "Last 7 days", value: "__last_7_days__" },
    { displayName: "Last 14 days", value: "__last_14_days__" },
    { displayName: "Last 30 days", value: "__last_30_days__" },
]

const stateOptions = [
    { displayName: "Published", value: "Published" },
    { displayName: "Draft", value: "Draft" },
]

const roleOptions = [
    { displayName: "Owner", value: "owner" },
    { displayName: "Member", value: "member" },
]

const visiblityOptions = [
    { displayName: "Visible", value: "Visible" },
    { displayName: "Hidden", value: "Hidden" },
]

const lockedOptions = [
    { displayName: "Unlocked", value: "Unlocked" },
    { displayName: "Locked", value: "Locked" },
]

const usagesOptions = [
    { displayName: "Has Usages", value: "Has Usages" },
    { displayName: "No Usages", value: "No Usages" },
]

const showTime = computed(() => {
    return !customFields.some((field) => localFilter.value.field.endsWith(field))
})

const filterButtonText = computed(() => (props.isUpdated ? "Update filter" : "Apply filter"))

const isBetweenRule = computed(() => ["isBetween", "isNotBetween"].includes(localFilter.value.rule as string))
const isDateTypeFilter = computed(() => ["date", "eventtime"].includes(localFilter.value.type?.toLowerCase() as string))
const isNumberTypeFilter = computed(() => localFilter.value.type?.toLowerCase() === "number")
const isNotCompareableAttribute = computed(() => ["isEmpty", "isNotEmpty"].includes(localFilter.value.rule as string))
const localFilterInputType = computed(() => {
    if (isNumberTypeFilter.value) {
        return "number"
    }
    return "text"
})
const localFilterStart = ref(new Date((props.filter.value as Date[])?.[0] || ""))
const localFilterEnd = ref(new Date((props.filter.value as Date[])?.[1] || ""))

const localFilterNumberStartValue = ref(Array.isArray(localFilter.value.value) ? localFilter.value.value?.[0] : "")
const localFilterNumberEndValue = ref(Array.isArray(localFilter.value.value) ? localFilter.value.value?.[1] : "")

const hasFilter = computed(() => !!filterRules.value?.length)
const tagsSelectedState = ref<boolean[]>([])
const originsSelectedState = ref<boolean[]>([])
const collectionsSelectedState = ref<boolean[]>([])
const collectionType = computed(() => gridCollectionStore.collectionType.replace(/s$/, ""))

const typeFilterOptions = ref(setFilterTypeOptions())

const filterRules = computed(() => {
    let rules: string[] = []

    if (
        ["type", "shouldRemoveIfNotCompleted", "lists._id", "isUsed"].includes(localFilter.value.field) ||
        localFilter.value.type?.toLowerCase() === "array"
    ) {
        return rules
    }

    if (localFilter.value.type?.toLowerCase() === "object") {
        switch (localFilter.value.field) {
            case "when.startedAt":
                rules = ["isLessThan", "isGreaterThan"]
                break
            case "agent.fullName":
            case "phoneNumber.number":
            case "phone.number":
            case "collection.displayName":
                rules = ["isEmpty", "isNotEmpty", "contains", "doesNotContain", "isEqualTo", "isNotEqualTo"]
                break
        }

        return rules
    }

    if (localFilter.value.field === "attributes.petsAllowed") {
        rules = ["isNotEmpty"]
        return rules
    }

    switch (localFilter.value.type?.toLowerCase()) {
        case "email":
        case "phonenumber":
        case "string":
            rules = ["isEmpty", "isNotEmpty", "contains", "doesNotContain", "isEqualTo", "isNotEqualTo"]
            break
        case "number":
            rules = [
                "isEmpty",
                "isNotEmpty",
                "isEqualTo",
                "isNotEqualTo",
                "isLessThan",
                "isGreaterThan",
                "isGreaterThanOrEqualTo",
                "isLessThanOrEqualTo",
                "isBetween",
                "isNotBetween",
            ]
            break
        case "date":
        case "eventtime":
            rules = ["isEqualTo", "isLessThan", "isGreaterThan", "isBetween", "isNotBetween"]
            break
        case "email[]":
            rules = ["isEmpty", "isNotEmpty", "contains", "doesNotContain"]
            break
        case "phonenumber[]":
            rules = ["isEmpty", "isNotEmpty", "contains", "doesNotContain"]
            break
    }
    return rules
})

const isFilterValid = computed(() => {
    if (
        localFilter.value.field === "shouldRemoveIfNotCompleted" ||
        localFilterTags.value.value?.length ||
        localFilterOrigins.value.value?.length ||
        localFilterCollections.value.value?.length
    ) {
        return true
    }

    if (!localFilter.value.rule) {
        return false
    }

    return !(
        ["contains", "doesNotContain", "isLessThan", "isGreaterThan", "isBetween"].includes(
            localFilter.value.rule || ""
        ) && !localFilter.value.value
    )
})

function getFilterValue() {
    if (localFilterTags.value.value?.length) {
        return localFilterTags.value
    }
    if (localFilterOrigins.value.value?.length) {
        return localFilterOrigins.value
    }
    if (localFilterCollections.value.value?.length) {
        return localFilterCollections.value
    }
    return localFilter.value
}

// Define transformations for specific fields
const fieldTransformations = {
    // forms
    state: () => {
        localFilter.value.field = "isDraft"
        localFilter.value.type = "state"
    },
    //agents
    role: () => {
        localFilter.value.field = "invites?.role.name"
    },
    status: () => {
        if (gridCollectionStore.collectionType === "agents") {
            localFilter.value.field = "invites.status"
        }
        if (gridCollectionStore.collectionType === "workflows") {
            localFilter.value.field = "status"
        }
    },
    inviteStatus: () => {
        localFilter.value.field = "invites.inviteStatus"
    },
    email: () => {
        if (collectionType.value !== "agent") {
            localFilter.value.field = "email.emailAddress"
        }
    },
    phoneNumber: () => {
        localFilter.value.field = "phoneNumber.number"
    },
}

function applyDateFilter() {
    localFilter.value.value = isBetweenRule.value
        ? Array.isArray(localFilter.value.value)
            ? localFilter.value.value.map((date) => {
                  // Check if the date is undefined, null, or an empty string
                  if (!date) {
                      // If it is, return an empty string
                      return ""
                  } else {
                      // If it's not, format the date as before
                      return hasTime(date as string) ? formatDateToUtc(date) : formatDateWithoutTime(date as string)
                  }
              })
            : []
        : hasTime(localFilter.value.value?.toString() ?? "")
        ? formatDateToUtc(localFilter.value.value?.toString() ?? "")
        : formatDateWithoutTime(localFilter.value.value?.toString() ?? "")
}

// Check if a date string has a time component
function hasTime(dateString: string) {
    // Split the date string on space or 'T' to get the time part
    const timePart = dateString.split(" ")[1] || dateString.split("T")[1]
    // Check if the time part exists and is not '00:00:00'
    return timePart && timePart !== "00:00:00"
}

// Format a date string without a time component
function formatDateWithoutTime(dateString: string) {
    // Split the date string on '-' to get the year, month, and day parts
    const [year, month, day] = dateString.split("-").map(Number)
    // Return a date string in the format 'YYYY-MM-DDT00:00:00'
    return `${year}-${pad(month)}-${pad(day)}`
}

// Pad single digit numbers with a leading zero
function pad(number: number) {
    // If the number is less than 10, prepend a '0'. Otherwise, return the number as is.
    return number < 10 ? "0" + number : number
}

// Apply number filter transformation
function applyNumberFilter() {
    // Apply the start and end values only if they are defined and not empty strings
    localFilter.value.value = isBetweenRule.value
        ? [
              localFilterNumberStartValue.value !== "" && localFilterNumberStartValue.value !== null
                  ? Number(localFilterNumberStartValue.value).toString()
                  : "",
              localFilterNumberEndValue.value !== "" && localFilterNumberEndValue.value !== null
                  ? Number(localFilterNumberEndValue.value).toString()
                  : "",
          ]
        : localFilterNumberStartValue.value !== "" && localFilterNumberStartValue.value !== null
        ? Number(localFilterNumberStartValue.value).toString()
        : localFilter.value.value !== null
        ? localFilter.value.value
        : ""
}

// Apply boolean filter transformation
function applyBooleanFilter() {
    // By default, set the rule to 'isEqualTo'
    localFilter.value.rule = "isEqualTo"
    // If the field is'isUsed', set the rule to 'isIn'
    if (["isUsed"].includes(localFilter.value.field)) {
        localFilter.value.rule = "isIn"
    }
}

// Apply the appropriate filter based on the type and field
function applyFilter() {
    // If the rule is 'between', apply the date or number filter as appropriate
    if (isBetweenRule.value) {
        if (localFilter.value.type === "Date") {
            applyDateFilter()
        } else if (localFilter.value.type === "number") {
            applyNumberFilter()
        }
    } else {
        // If the rule is not 'between', apply the appropriate filter based on the type
        if (localFilter.value.type === "Date") {
            applyDateFilter()
        } else if (localFilter.value.type === "number") {
            applyNumberFilter()
        } else if (localFilter.value.type === "boolean") {
            applyBooleanFilter()
        } else {
            // If the type is not 'Date', 'number', or 'boolean', apply the field transformation if one exists
            const transform = fieldTransformations[localFilter.value.field]
            if (transform) {
                transform()
            }
        }
    }

    const filterValue = getFilterValue()

    if (
        (gridCollectionStore.collectionType === "listings" ||
            gridCollectionStore.collectionType === "contact-listings") &&
        !filterValue.field.startsWith("attributes.")
    ) {
        filterValue.field = `attributes.${filterValue.field}`
    }

    if (gridCollectionStore.collectionType === "templates" && filterValue.field === "type") {
        filterValue.field = "templateType"
    }

    emit("applyFilter", filterValue)
}

function cancelFilter() {
    emit("cancel")
}

function onTagSelect(selectedTagsIds: string[]) {
    localFilterTags.value.value = Array.from(selectedTagsIds)
}

function onOriginSelect(selectedOriginsIds: string[]) {
    localFilterOrigins.value.value = Array.from(selectedOriginsIds)
}

function onCollectionSelect(selectedCollectionsIds: string[]) {
    localFilterCollections.value.value = Array.from(selectedCollectionsIds)
}

function changeLocalFilterValueType() {
    if (localFilter.value.type === "Date") {
        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)

        localFilter.value.value = isBetweenRule.value ? [today, tomorrow] : today
        localFilterStart.value = today
        localFilterEnd.value = tomorrow
        return
    }

    localFilter.value.value = isBetweenRule.value ? ["", ""] : ""
}

function setOptions(options, filterValue) {
    multipleModelValues.value = filterValue?.length
        ? options.map((option) => filterValue.includes(option.value))
        : new Array(options.length).fill(false)

    return options
}

function setFilterTypeOptions() {
    if (collectionType.value === "task") {
        if (localFilter.value.field === "status") {
            return setOptions(statusOptions, props.filter.value)
        }

        const taskOptions = getTaskTypes().map((type) => ({ displayName: type.value, value: type.attribute }))
        return setOptions(taskOptions, props.filter.value)
    }

    if (collectionType.value === "workflow") {
        if (localFilter.value.field === "status") {
            return setOptions(workflowStatusOptions, props.filter.value)
        }
    }

    if (collectionType.value === "tag") {
        if (localFilter.value.field === "isUsed") {
            return setOptions(usagesOptions, props.filter.value)
        }

        const tagTypesOptions = tagStore.tagTypes.map((tag: { label: string; value: string }) => ({
            displayName: tag.label,
            value: tag.value,
        }))
        return setOptions(tagTypesOptions, props.filter.value)
    }

    if (collectionType.value === "form") {
        return setOptions(stateOptions, props.filter.value)
    }

    if (collectionType.value === "agent") {
        if (["status", "invites.status"].includes(localFilter.value.field)) {
            return setOptions(agentStatusOptions, props.filter.value)
        }

        return setOptions(roleOptions, props.filter.value)
    }

    if (collectionType.value === "messaging-template" || collectionType.value === "template") {
        const templateTypesOptions = [
            { displayName: "Text Message", value: "sms" },
            { displayName: "Email", value: "email" },
        ]
        return setOptions(templateTypesOptions, props.filter.value)
    }

    if (collectionType.value === "listing") {
        if (["attributes.poolFeatures", "poolFeatures"].includes(localFilter.value.field)) {
            return setOptions(poolFeaturesOptions, props.filter.value)
        } else if (["attributes.standardStatus", "standardStatus"].includes(localFilter.value.field)) {
            return setOptions(standardStatusOptions, props.filter.value)
        }
    }

    return []
}

function setLocalFilterValue(value: boolean[]) {
    const selectedTypes = [] as string[]
    value.forEach((isSelected, index) => {
        if (isSelected) {
            selectedTypes.push(
                typeFilterOptions.value[index] ? typeFilterOptions.value[index].value : statusOptions[index].value
            )
        }
    })

    localFilter.value.value = Array.from(selectedTypes)
    localFilter.value.rule = "isIn"
}

function onItemSelect(value: boolean) {
    if (localFilter.value.type === "boolean") {
        localFilter.value.value = value
    } else {
        localFilter.value.value = value.toString()
    }
    localFilter.value.rule = "isEqualTo"
}

onMounted(() => {
    cities.value = Object.values(statesAndCities).flat() || []

    if (props.filter.field === "tags._id") {
        localFilterTags.value = structuredClone(props.filter)
        tagsSelectedState.value = tagStore.tableState.data.map((tag) => localFilterTags.value.value.includes(tag._id))
        return
    }
    if (props.filter.field === "origins._id") {
        localFilterOrigins.value = structuredClone(props.filter)
        originsSelectedState.value = originStore.tableState.data.map((origin) =>
            localFilterOrigins.value.value.includes(origin._id)
        )
        return
    }
    if (props.filter.field === "lists._id") {
        localFilterCollections.value = structuredClone(props.filter)
        localFilterCollections.value.rule = "isInIds"
        collectionsSelectedState.value = gridCollectionStore.collectionTableState.data.map((collection) =>
            localFilterCollections.value.value.includes(collection._id)
        )
        return
    }
})

watch(localFilterStart, (newVal) => {
    if (newVal) {
        localFilter.value.value[0] = newVal.toString()
    }
})

watch(localFilterEnd, (newVal) => {
    if (newVal) {
        localFilter.value.value[1] = newVal.toString()
    }
})

watch(
    () => multipleModelValues.value,
    (newVal) => {
        setLocalFilterValue(newVal)
    },
    { deep: true }
)
</script>
