<template>
    <GgmsInput
        input-class="bg-gray-100 border-0"
        v-model="searchTerm"
        :small="true"
        :iconEnd="SearchIcon"
        placeholder="Search"
    ></GgmsInput>
    <div v-for="category in eventsCategories" :key="category">
        <div v-show="filteredEvents.filter((ev) => ev.type === category).length" class="flex flex-col mt-4">
            <div
                class="w-full bg-primary-color-50 text-primary-color-800 text-xs leading-4 font-semibold uppercase py-1 px-2 mb-2 rounded-md"
            >
                {{ category }}
            </div>
            <div
                v-for="event in filteredEvents.filter((ev) => ev.type === category)"
                :key="event"
                class="text-gray-900 text-base leading-6 font-medium p-2.5 cursor-pointer"
                @click="chooseEvent(event.title)"
            >
                {{ convertTriggerEvent(event.title) }}
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { computed, ref } from "vue"
import { SearchIcon } from "@heroicons/vue/outline"
import { useWorkflowStore } from "@/stores/workflow"
import { convertTriggerEvent } from "@/shared/utils/helpers"
import GgmsInput from "@/components/GgmsInput.vue"
import TriggerNode from "@/components/workflows/TriggerNode.vue"
import { ListingActivityTriggerNodeType, TriggerNodeType } from "@/shared/models/workflow"

const workflowStore = useWorkflowStore()

const searchTerm = ref("")
const eventsCategories = ["appointment"]

const events = [
    { title: ListingActivityTriggerNodeType.appointmentCreated, type: "appointment" },
    { title: ListingActivityTriggerNodeType.appointmentCreatedAgency, type: "appointment" },
]

const filteredEvents = computed(() =>
    events.filter((event) => event.title.toLowerCase().includes(searchTerm.value.toLowerCase()))
)

interface Props {
    modelValue: boolean
}
defineProps<Props>()

const emit = defineEmits(["update:modelValue", "changeAction"])

function chooseEvent(event: string) {
    workflowStore.modelValue = event
    emit("changeAction", { componentName: TriggerNode.__name, node: TriggerNodeType.listingActivity })
    workflowStore.setHeaderText()
}
</script>
