import { defineStore } from "pinia"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { useSocketStore } from "@/stores/socket"
import { Listing } from "@/shared/models/listing"
import { TableState } from "@/shared/models/table"
import { ListingService } from "@/shared/services/listings"
import { PropertyGroupLayoutService } from "@/shared/services/property-group-layout"
import { ToastService } from "@/shared/services/toast"
import { ToastModel } from "@/shared/models/toast-model"
import { Tag } from "@/shared/models/tag"
import { GgmsError } from "@/shared/services/client"
import { ContactListingService } from "../shared/services/contact-listings"

export const useContactListingStore = defineStore("contact-listings", {
    state: () => ({
        tableState: {
            data: [],
            selectedData: [],
            currentPage: 0,
            lastPage: 0,
            isListingSidebarOpen: false,
            pageLength: 50,
            numberOfPages: 0,
            total: 0,
            isAllSelected: false,
            isLoading: false,
            firstIds: [],
        } as TableState<Listing>,
        listing: {} as Listing,
        listingService: new ListingService(),
        propertyGroupLayoutService: new PropertyGroupLayoutService(),
        toastService: new ToastService(),
        gridCollectionStore: useGridCollectionStore(),
        socketStore: useSocketStore(),
    }),
    actions: {
        async getCRMLayoutGroupsForAgency() {
            const layout = await this.propertyGroupLayoutService.getCRMLayoutForAgency()

            return layout.groups
        },

        async getAll(filtersGroups?: string, lastId?: any, offset?: any) {
            return await this.listingService.getAll({
                length: this.tableState.pageLength,
                column: this.tableState.column,
                order: this.tableState.order,
                search: this.tableState.search,
                filtersGroups,
                lastId,
                offset,
            })
        },

        resetTableState() {
            this.tableState.data = []
            this.tableState.total = 0
            this.tableState.selectedData = []
            this.tableState.numberOfPages = Math.ceil(1)
        },

        async getListingById(id: string) {
            const listing = await this.listingService.getOne(id)

            if (!listing) {
                return
            }
            this.listing = listing
        },

        async getListingGrid() {
            const response = await this.gridCollectionStore.getGrid("contact-listings")
            return response
        },

        async updateListingGrid() {
            if (!this.gridCollectionStore.grid) return
            this.gridCollectionStore.grid.filtersGroups = this.gridCollectionStore.filtersGroups || []
            const response = await this.gridCollectionStore.updateGrid(
                "contact-listings",
                this.gridCollectionStore.grid
            )
            this.gridCollectionStore.gridResponse = response
            this.gridCollectionStore.grid = this.gridCollectionStore.gridResponse.grid
            return response
        },

        async bulkUpdateTagOnListing(
            listingIds: string[],
            toAdd: Tag[],
            toRemove: Tag[],
            all: boolean,
            toastObject?: Partial<ToastModel>
        ) {
            const { job } = await this.listingService.bulkUpdateTagOnListing(listingIds, toAdd, toRemove, all)
            this.socketStore.addJob(job)
            this.listing.tags = this.listing?.tags?.concat(toAdd)
            this.listing.tags = this.listing?.tags?.filter((tag) => !toRemove.find((t) => t._id === tag._id))

            this.toastService.addToast({
                type: toastObject?.type || "success",
                message:
                    toastObject?.message || `Tag changes applied to ${all ? "all" : listingIds.length} listing(s).`,
            })
        },

        async checkListingList(typename: string, entityIds: string[], contactId?: string) {
            try {
                return this.listingService.checkListingList(typename, entityIds, contactId)
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.toastService.addToast({
                        type: "error",
                        message: err.message,
                    })
                }
            }
        },
    },
})
