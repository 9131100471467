<template>
    <div>
        <div class="flex">
            <label v-if="label" for="comment" class="block text-sm font-medium mb-1 text-gray-700">{{ label }}</label>
            <span v-if="required" :class="starStyle">*</span>
        </div>
        <p v-if="description" class="text-sm text-gray-500 mt-3 mb-4">
            {{ description }}
        </p>
        <div class="relative">
            <div v-show="roundedTop" class="bg-white h-2 rounded-t-md"></div>
            <textarea
                ref="textarea"
                :value="modelValue"
                :placeholder="placeholder"
                :rows="rows"
                :class="textAreaStyle"
                :readonly="readonly"
                :disabled="disabled"
                @input="onInput"
                @paste="onPaste"
                @blur="showErrors = true"
            />
            <div
                v-if="localInvalid && showErrors && !modelValue"
                class="absolute z-10 top-2.5 right-0 pr-3 flex items-center cursor-pointer"
            >
                <component :is="ExclamationCircleIcon" class="h-5 w-5 text-red-600" aria-hidden="true" />
            </div>
            <div v-show="roundedBottom" class="bg-white h-2 rounded-b-md"></div>
        </div>
        <p v-if="localErrorMessage && showErrors" class="mt-2 text-sm text-red-600">{{ localErrorMessage }}</p>
        <p v-if="charLimitReached && props.isForSMS" class="mt-2 text-sm text-red-600">Characters limit reached</p>
        <div v-if="isCountAvailable" class="flex w-full gap-x-4 mt-2">
            <p class="text-sm text-gray-500">
                Characters: <span class="font-medium text-gray-700">{{ modelValue.length }}</span>
            </p>
            <p class="text-sm text-gray-500">
                SMS: <span class="font-medium text-gray-700">{{ smsCount }}</span>
            </p>
            <p class="text-sm text-gray-500">
                Cost:
                <span
                    :class="cost > (remainingPoints ?? 0) ? 'text-orange-500' : ''"
                    class="font-medium text-gray-700"
                    >{{ cost }}</span
                >
                <span class="font-medium text-gray-400">/{{ remainingPoints }}</span>
            </p>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { withDefaults, computed, onMounted, watch, ref, nextTick } from "vue"
import { ExclamationCircleIcon } from "@heroicons/vue/solid"
import { useAgencyStore } from "@/stores/agency"

const emit = defineEmits(["update:modelValue", "disableValidationError", "smsCountChanged", "costExceeded"])

const MAX_CHAR_LIMIT = 1400

interface Props {
    modelValue: string
    templateString?: string
    label?: string
    description?: string
    rows?: number
    placeholder?: string
    invalid?: boolean
    errorMessage?: string
    textAreaClasses?: string[]
    readonly?: boolean
    isCountAvailable?: boolean
    numberOfRecipients?: number
    required?: boolean
    disabled?: boolean
    rounded?: boolean
    borderless?: boolean
    roundedTop?: boolean
    roundedBottom?: boolean
    isForSMS?: boolean
    styleType?: "primary" | "onlyEmail" | "onlyPhone" | "emailAndPhone"
    overwriteShowErrors?: boolean
}

const textarea = ref()

const props = withDefaults(defineProps<Props>(), {
    modelValue: "",
    templateString: "",
    label: "",
    rows: 4,
    placeholder: "",
    tedxtAreaClasses: "",
    readonly: false,
    isCountAvailable: false,
    numberOfRecipients: 1,
    required: false,
    disabled: false,
    rounded: true,
    borderless: false,
    roundedTop: false,
    roundedBottom: false,
    isForSMS: false,
    styleType: "primary",
    overwriteShowErrors: false,
})

const agencyStore = useAgencyStore()

const smsCount = computed(() => calculateNumberOfMessages(props.modelValue))

const cost = computed(() => {
    const costPerSms = 1
    return smsCount.value * costPerSms * (props.numberOfRecipients > 0 ? props.numberOfRecipients : 1)
})

// Computed property to check if the character limit is reached
const charLimitReached = computed(() => props.modelValue.length >= MAX_CHAR_LIMIT)

const remainingPoints = computed(() => agencyStore.agency.points?.balance)

const localErrorMessage = ref(props.errorMessage)
const localInvalid = ref(props.invalid)
const showErrors = ref(true)

const styleTypeToHeight = {
    onlyEmail: "h-[343px]",
    onlyPhone: "h-[221px]",
    emailAndPhone: "h-[282px]",
    primary: "",
}

const textAreaStyle = computed(() => [
    props.borderless ? "border-0 focus:border-0 focus:ring-transparent" : "",
    props.disabled ? "opacity-50 cursor-not-allowed" : "",
    localInvalid.value && showErrors.value && !props.modelValue?.length
        ? "pr-10 border-red-300 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
        : "placeholder-gray-400 focus:ring-primary-color-500 focus:border-primary-color-500 border-gray-300 ",
    props.readonly ? "opacity-50 focus:ring-transparent focus:border-gray-300 cursor-not-allowed" : "",
    props.rounded ? "rounded-md" : "",
    "shadow-sm block w-full ",
    props.textAreaClasses,
    styleTypeToHeight[props.styleType] || "",
])

const starStyle = computed(() => [localInvalid.value ? "text-red-600" : "text-gray-700", "pl-1"])

const onInput = (event: Event) => {
    disableValidationError()
    const target = event.target as HTMLTextAreaElement
    let newValue = target.value

    // Apply character limit only if isForSMS is true
    if (props.isForSMS && newValue.length > MAX_CHAR_LIMIT) {
        // Trim the input to the character limit
        newValue = newValue.slice(0, MAX_CHAR_LIMIT)
        // Set the textarea's value to the trimmed input
        target.value = newValue
    }

    emit("update:modelValue", newValue)

    // Trigger SMS count recalculation and cost update
    const newSmsCount = calculateNumberOfMessages(newValue)
    emit("smsCountChanged", newSmsCount)

    // Check if the cost exceeds the available points
    if (cost.value > remainingPoints.value) {
        emit("costExceeded", true)
    } else {
        emit("costExceeded", false)
    }
}

const onPaste = (event: ClipboardEvent) => {
    disableValidationError()
    if (event.clipboardData) {
        const pastedText = event.clipboardData.getData("text")
        const target = event.target as HTMLTextAreaElement
        const cursorPosition = target.selectionStart
        const textAreaValue = target.value

        const textBefore = textAreaValue.substring(0, cursorPosition)
        const textAfter = textAreaValue.substring(cursorPosition, textAreaValue.length)

        let newValue = `${textBefore}${pastedText}${textAfter}`

        // Apply character limit only if isForSMS is true
        if (props.isForSMS && newValue.length > MAX_CHAR_LIMIT) {
            event.preventDefault()
            // Trim the pasted text to the remaining characters
            const remainingChars = MAX_CHAR_LIMIT - textBefore.length - textAfter.length
            const trimmedText = pastedText.slice(0, remainingChars)
            target.value = `${textBefore}${trimmedText}${textAfter}`
            emit("update:modelValue", target.value)
        } else {
            emit("update:modelValue", newValue)
            nextTick(() => onInput(event as unknown as Event)) // Ensure the input is recalculated
        }
    }
}

function calculateNumberOfMessages(textMessage: string) {
    if (!textMessage) {
        return 0
    }

    // Unicode characters (such as emojis) will reduce the character limit per SMS
    const GSM_MAX_LENGTH = 160
    const UNICODE_MAX_LENGTH = 70

    // Detect if the message contains Unicode characters
    // eslint-disable-next-line no-control-regex
    const isUnicode = /[^\u0000-\u007F]/.test(textMessage)
    const maxMessageLength = isUnicode ? UNICODE_MAX_LENGTH : GSM_MAX_LENGTH

    // If the message exceeds the limit for a single SMS, calculate how many parts are needed
    const messagePartSize = isUnicode ? UNICODE_MAX_LENGTH - 3 : GSM_MAX_LENGTH - 7

    if (textMessage.length > maxMessageLength) {
        return Math.ceil(textMessage.length / messagePartSize)
    } else {
        return 1
    }
}

function disableValidationError() {
    localErrorMessage.value = ""
    localInvalid.value = false
    if (props.overwriteShowErrors) {
        showErrors.value = false
    }
    emit("disableValidationError")
}

function onSelectInsertString(templateString: string) {
    const cursorPosition = textarea.value.selectionStart
    const textAreaValue = textarea.value.value
    const textBefore = textAreaValue.substring(0, cursorPosition)
    const textAfter = textAreaValue.substring(cursorPosition, textAreaValue.length)
    textarea.value.value = `${textBefore}${templateString}${textAfter}`
    textarea.value.focus()
    textarea.value.selectionStart = cursorPosition + templateString.length
    textarea.value.selectionEnd = cursorPosition + templateString.length
    emit("update:modelValue", textarea.value.value)
}

onMounted(() => {
    disableValidationError()
})

watch(
    () => props.templateString,
    (newTemplateString) => {
        if (newTemplateString) {
            // Call onSelectInsertString to insert the template string into the textarea
            onSelectInsertString(newTemplateString)

            // Create a synthetic event to pass to the onInput handler
            const syntheticEvent = {
                target: textarea.value,
            } as Event

            // Call onInput with the synthetic event to trigger SMS count and cost updates
            onInput(syntheticEvent)
        }
    }
)

watch(
    () => cost.value,
    () => {
        if (cost.value > remainingPoints.value) {
            emit("costExceeded", true)
        } else {
            emit("costExceeded", false)
        }
    }
)

watch(
    () => props.errorMessage,
    (newVal) => {
        localErrorMessage.value = newVal
        localInvalid.value = props.invalid
    }
)

watch(
    () => props.invalid,
    () => {
        localInvalid.value = props.invalid
    }
)

watch(
    () => props.modelValue,
    () => {
        if (!props.modelValue && props.invalid) {
            localErrorMessage.value = props.errorMessage
            localInvalid.value = props.invalid
        }
    }
)

defineExpose({
    textarea,
})
</script>
